#main-container {
  display: inline;
  width: 100%;
  /* max-width: 1280px;  */
  /* padding-left: 10%;
  padding-right: 10%;  */
  color: white;
  background-color: #161616;
}

#nota {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  font-size: small;
}

#secao-comunicados {
  margin-top: 5%;
  margin-bottom: 10%;
}

#container-comunicados {
  display: flex;
  text-align: center;
  flex-direction: row;
  margin-top: 4rem;
  justify-content: space-around;

}

#ultimo-relatorio {
  margin-top: 1rem;
}

#secao-rentabilidade {
  margin-bottom: 4rem;
}

#container-rentabilidade {
  display: flex;
  text-align: center;
  flex-direction: row;
  justify-content: center;
}

#historico {
  margin-bottom: 2rem;
  margin-top: 5%;
  text-align: center;
  font-weight: bold;
  color: black;
  background-color: white;
}

h2 {
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}


.detalhes-historico {
  align-content: center;
}

.sumario {
  font-size: large;
}

details {
  background-color: #f3f3f3;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 10%;
  width: 80%;
}

details summary {
  cursor: pointer;
  text-align: le;
}

details summary::marker {
  color: #161616;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-weight: normal;
}

table tbody td:empty {
  padding-top: 1rem;
  background-color: #ffffff;
}

table th,
table td {
  padding: 4px;
  color: #4e4d4d;
  border: 2px solid #ffffff;
  text-align: center;
}

table th {
  color: black;
}

.text-sm {
  font-size: 0.875rem;
}

.container {
  padding: 1rem;
}

.desc {
  font-size: 0.875rem;
  color: #333;
}


.button {
  font-weight: bold;
  text-decoration: none;
  background-color: grey;
  color: white;
  padding: 4px 8px 4px 8px;
  cursor: pointer;
  border-radius: 8px;
}